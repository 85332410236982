.mainLayout {
 display: flex;
 height: 100vh;
}

.mainLayout__col {
 flex: 1;
 padding: 1.875rem;
}

.mainLayout__col:not(:last-child) {
 box-shadow: 0 30px 10px rgba(0, 0, 0, .2);
}